var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"email-modal"},[_c('CModal',{staticClass:"text-black email-docs",attrs:{"show":_vm.showPopup,"close-on-backdrop":false,"color":"primary","size":"lg"},on:{"update:show":function($event){_vm.showPopup=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title text-white"},[_vm._v("Document")]),_c('CButtonClose',{staticClass:"text-black",on:{"click":_vm.documentModalCallBack}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{staticClass:"px-4",attrs:{"type":"button","color":"primary","variant":"outline"},on:{"click":_vm.documentModalCallBack}},[_vm._v("Close")])]},proxy:true}])},[_c('div',{staticClass:"d-flex justify-content-between w-100"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 px-0"},[_c('div',[_c('ul',{staticClass:"nav nav-tabs"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link bg-white rounded-0",class:{ active: _vm.currentTab == 'Candidate Docs' },attrs:{"href":"#email-config","target":"_self"},on:{"click":function($event){_vm.currentTab = 'Candidate Docs'}}},[_vm._v("Candidate Docs")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link bg-white rounded-0",class:{ active: _vm.currentTab == 'Library Docs' },attrs:{"href":"#email-assets","target":"_self"},on:{"click":function($event){_vm.currentTab = 'Library Docs'}}},[_vm._v("Library Docs")])])])])])]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane",class:{ active: _vm.currentTab == 'Candidate Docs' },style:(_vm.currentTab == 'Candidate Docs' ? '' : 'display:none;')},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[(_vm.isShowHideCandidate)?_c('div',[_c('div',{staticClass:"generic-filter mt-3"},[_c('SearchBar',{staticClass:"my-1",attrs:{"value":_vm.searchTerm,"searchCallback":_vm.handleSearchTerm,"placeholder":'Search by candidate id, name and email id'}})],1),(
                _vm.getCandidateEmailAddress && _vm.getCandidateEmailAddress.length
              )?_c('CRow',{staticClass:"row mt-4"},_vm._l((_vm.getCandidateEmailAddress),function(item,index){return _c('div',{key:index,staticClass:"col-sm-6 col-md-3"},[_c('CCard',{staticClass:"candidate-card cursour-pointer",style:({ height: _vm.windowWidth ? '180px' : '230px' }),on:{"click":function($event){_vm.getCandidateDocument(item), (_vm.isShowHideCandidate = false)}}},[_c('CCardBody',[_c('div',{staticClass:"content-title mb-3 text-center"},[_c('div',{staticClass:"image-wrapper"},[_c('img',{staticClass:"rounded-circle",class:_vm.getWindowWidth > 1200 ? 'w-50' : 'w-25',attrs:{"src":item.profile_picture,"alt":"DP"},on:{"error":function($event){$event.target.src = _vm.userIcon}}})]),_c('div',{staticClass:"mt-4"},[_c('span',{staticClass:"d-block"},[_c('h6',{staticClass:"m-0 mt-2 text-wrapper"},[_vm._v(" "+_vm._s(item.full_name)+" ")])]),_c('span',{staticClass:"d-block"},[_c('p',{staticClass:"m-0 mt-2 text-wrapper",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.email)+" ")])])])])])],1)],1)}),0):_c('CRow',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ul',{staticClass:"list-group list-group-flush",attrs:{"colspan":"4"}},[_c('li',{staticClass:"my-5 list-style-none"},[_c('div',{staticClass:"text-center my-5"},[_c('h5',{staticClass:"text-center",style:('color:')},[_vm._v(" No Data Found "),_c('i',{staticClass:"fas fa-ban",style:({ color: '#e55353' })})])])])])])])],1):_c('div',[_c('CRow',[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('div',{staticClass:"float-back-button position-absolute mt-2 mx-3"},[_c('CButton',{staticClass:"px-4",attrs:{"type":"button","color":"primary"},on:{"click":function($event){_vm.isShowHideCandidate = true}}},[_vm._v("Back")])],1),_c('CDataTable',{attrs:{"items":_vm.getDocuments,"fields":_vm.documentFields,"items-per-page":10,"tableFilter":{
                    placeholder: 'Type for search',
                  },"hover":"","pagination":""},scopedSlots:_vm._u([{key:"select_to_attach",fn:function({ item }){return [_c('td',[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.checkFileExist(item, 'candidate')},on:{"click":function($event){return _vm.selectToAttach($event, item, 'candidate')}}})])]}},{key:"preview",fn:function({ item }){return [_c('td',[(item && item.link)?_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.preview(item)}}},[_c('i',{staticClass:"fas fa-eye ml-3"})]):_vm._e()])]}},{key:"no-items-view",fn:function(){return [_c('ul',{staticClass:"list-group list-group-flush",attrs:{"colspan":"4"}},[_c('li',{staticClass:"my-5"},[_c('div',{staticClass:"text-center my-5"},[_c('h5',{staticClass:"text-center",style:('color:')},[_vm._v(" No Data Found "),_c('i',{staticClass:"fas fa-ban",style:({ color: '#e55353' })})])])])])]},proxy:true}])})],1)])],1)])]),_c('div',{staticClass:"tab-pane",class:{ active: _vm.currentTab == 'Library Docs' },style:(_vm.currentTab == 'Library Docs' ? '' : 'display:none;')},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 p-0"},[_c('CDataTable',{attrs:{"items":_vm.library,"fields":_vm.documentFields,"items-per-page":10,"tableFilter":{
              placeholder: 'Type for search',
            },"hover":"","pagination":""},scopedSlots:_vm._u([{key:"select_to_attach",fn:function({ item }){return [_c('td',[(_vm.checkFileExist(item, 'org'))?_c('input',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content:
                      'Use delete icon from email screen to remove the files!',
                    show: false,
                    placement: 'bottom',
                    appendToBody: true,
                  }),expression:"{\n                    content:\n                      'Use delete icon from email screen to remove the files!',\n                    show: false,\n                    placement: 'bottom',\n                    appendToBody: true,\n                  }"}],attrs:{"type":"checkbox"},domProps:{"checked":_vm.checkFileExist(item, 'org')},on:{"click":function($event){return _vm.selectToAttach($event, item, 'org')}}}):_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.checkFileExist(item, 'org')},on:{"click":function($event){return _vm.selectToAttach($event, item, 'org')}}})])]}},{key:"preview",fn:function({ item }){return [_c('td',[(item && item.link)?_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.preview(item)}}},[_c('i',{staticClass:"fas fa-eye ml-3"})]):_vm._e()])]}},{key:"no-items-view",fn:function(){return [_c('ul',{staticClass:"list-group list-group-flush",attrs:{"colspan":"4"}},[_c('li',{staticClass:"my-5"},[_c('div',{staticClass:"text-center my-5"},[_c('h5',{staticClass:"text-center",style:('color:')},[_vm._v(" No Data Found "),_c('i',{staticClass:"fas fa-ban",style:({ color: '#e55353' })})])])])])]},proxy:true}])})],1)])])]),(_vm.previewModal.isShowPopup)?_c('PreviewModal',{attrs:{"modalTitle":_vm.previewModal.modalTitle,"modalColor":_vm.previewModal.modalColor,"isShowPopup":_vm.previewModal.isShowPopup,"buttons":_vm.previewModal.buttons,"modalCallBack":_vm.modalCallBack,"isPreview":_vm.previewModal.isPreview,"iframe":_vm.iframe,"size":_vm.previewModal.size,"closeOnBackdrop":_vm.previewModal.closeOnBackdrop}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }