<template>
  <div class="email-modal">
    <CModal
      class="text-black"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      color="primary"
    >
      <template #header>
        <h6 v-if="modal === 'whatsapp'" class="modal-title">
          Whatsapp Conversation - {{ message.phone }}
        </h6>
        <h6 v-else-if="modal === 'sms'" class="modal-title">
          SMS Conversation - {{ message.phone }}
        </h6>
        <CButtonClose
          @click="modalCallBack"
          class="text-black"
          :disabled="isDisable"
        />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="modalCallBack"
          :disabled="isDisable"
          >Close</CButton
        >
      </template>
      <ValidationObserver ref="messageform" v-slot="{ handleSubmit }">
        <form
          id="message"
          @submit.prevent="handleSubmit(sendMessage)"
          name="messageform"
        >
          <div class="p-2">
            <CRow class="row mb-3" style="display: none">
              <label class="col-lg-12 col-md-12">Mobile Number</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <TextInput
                    name="phone"
                    :value="message.phone"
                    @change="handleInput"
                    :error="errors[0]"
                    :disabled="true"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <label class="col-lg-12 col-md-12">Message</label>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <TextareaInput
                    name="message"
                    :value="message.message"
                    :error="errors[0]"
                    :maxlength="max"
                    @change="handleInput"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mb-3">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <CButton
                  type="button"
                  v-if="btnHideSend"
                  color="primary"
                  class="lx-4"
                  @click="sendMessage()"
                  >{{ btnText }}</CButton
                >
                <CButton
                  type="button"
                  v-if="btnHideNext"
                  color="primary"
                  class="lx-4"
                  @click="openCommentModal"
                  >Next</CButton
                >
              </div>
            </CRow>
          </div>
        </form>
      </ValidationObserver>

      <MessageConversation />
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import { mapGetters, mapActions } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import MessageConversation from "@/containers/Communication/MessageConversation";
import { Role, getScope } from "@/helpers/helper";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: String,
      default: null,
    },
    modal: {
      type: String,
      default: null,
    },
  },
  components: {
    TextInput,
    TextareaInput,
    MessageConversation,
  },

  data() {
    return {
      Role,
      currentUserRole: getScope(),
      btnText: "Send Message",
      message: {
        phone: this.phone.replace('^', ''),
        message: null,
      },
      max: 1600,
      btnHideSend: true,
      btnHideNext: false,
      isDisable: false,
      messageId: null,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    getUserName() {
      let firstname = this.getUser.first_name;
      let lastname = this.getUser.surname;

      if (firstname && lastname) {
        firstname = firstname
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .replace(/\s/g, "");

        lastname = lastname
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .replace(/\s/g, "");

        return firstname + " " + lastname;
      }
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "getMessageConversation",
      "sendOutboundWhatsapp",
      "sendOutboundMessage",
    ]),
    handleInput(name, value) {
      Vue.set(this.message, name, value);
    },
    modalCallBack() {
      if (this.modal == "sms") {
        this.$emit("messageModalCallBack", "sms");
      } else if (this.modal == "whatsapp") {
        this.$emit("messageModalCallBack", "whatsapp");
      }
    },
    openCommentModal() {
      if (this.modal == "sms") {
        this.$emit("openCommentModal", this.messageId, "sms");
      } else if (this.modal == "whatsapp") {
        this.$emit("openCommentModal", this.messageId, "whatsapp");
      }
      
      this.isDisable = false;
    },
    async sendMessage() {
      const isValid = await this.$refs.messageform.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.btnText = "Sending...";
      let receiver = encodeURIComponent(this.message.phone);
      let appendAction = null;
      if (this.modal == "whatsapp") {
        appendAction = this.sendOutboundWhatsapp({
          sender: this.getUserName,
          whatsapp_to: receiver,
          message: this.message.message,
        });
      } else if (this.modal == "sms") {
        appendAction = this.sendOutboundMessage({
          sender: this.getUserName,
          message_to: receiver,
          message: this.message.message,
        });
      }
      Promise.all([appendAction]).then((res) => {
        if (res) {
          if (
            res == 500 || 
            res == 422 || 
            res == 400 || 
            res == 401 || 
            res == 403 || 
            res == 404 ||
            res == 322 ||
            res == 502
          ) {
            this.btnText = "Send Message";
          } else if (
            this.currentUserRole === this.Role.systemRecruiter &&
            this.$router.currentRoute.name == "Recruiter DashBoard" ||
            [this.Role.customerCandidate,this.Role.systemCandidate].includes(this.currentUserRole) &&
            this.$router.currentRoute.name == "Recruiter DashBoard" ||
            this.currentUserRole === Role.customerRecruiter &&
            this.$router.currentRoute.name == "Recruiter DashBoard" ||
            this.currentUserRole === this.Role.customerRecruiter &&
            this.$router.currentRoute.name == "Recruiter DashBoard"
          ) {

            this.btnText = "Send Message";
          } else if (
            this.currentUserRole === this.Role.systemAdmin &&
            this.$router.currentRoute.name === "Job List Preview" ||
            this.currentUserRole === this.Role.customerAdmin &&
            this.$router.currentRoute.name === "Job List Preview" ||
            this.currentUserRole === this.Role.systemRecruiter &&
            this.$router.currentRoute.name === "Job List Preview" ||
            this.currentUserRole === this.Role.customerRecruiter &&
            this.$router.currentRoute.name === "Job List Preview"
            
          ) {
            this.btnHideSend = false;
            this.btnHideNext = true;
            this.isDisable = true;
            this.messageId = res[0]?.data.split("-")[1];
          }
          //Clear Control
          this.$refs.messageform.reset();
          this.message.message = null;
          let sender = encodeURIComponent("+14155238886");
          this.getMessageConversation({
            sender: sender,
            receiver: receiver,
            media: this.modal,
          });
        }
      });
    },
  },
  mounted() {
    let sender = encodeURIComponent("+14155238886");
    let receiver = encodeURIComponent(this.message.phone);
    this.getMessageConversation({
      sender: sender,
      receiver: receiver,
      media: this.modal,
    });
  },
};
</script>
<style lang="scss" scoped>
.lx-4 {
  float: right;
}
</style>