<template>
  <div class="slider">
    <div class="side-bar" v-if="isShowSideBar">
      <div class="side-backdrop" @click="ShowHideSideBar"></div>
      <div class="side-panel">
        <div class="email-header">
          <CRow class="row mb-2">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <Select
                class="f-size"
                name="org"
                :value="mail.org"
                @input="handleChangeSelect"
                :options="
                  options && options['organisationList']
                    ? options['organisationList']
                    : []
                "
                :taggable="false"
                :multiple="false"
                :clearable="false"
              />
            </div>
          </CRow>
          <CDropdown placement="bottom-end" :show.sync="isShowDrop">
            <template #toggler>
              <span class="material-icons dot-icon cursor-pointer">
                more_vert
              </span>
            </template>
            <div class="dropdown-item-group">
              <CLink
                class="dropdown-item cursor-pointer"
                @click="getCustomers(false)"
                >User</CLink
              >
              <CLink
                class="dropdown-item cursor-pointer"
                @click="getCustomers(true)"
                >Contact</CLink
              >
              <CLink
                v-if="
                  [Role.customerAdmin, Role.customerRecruiter].includes(
                    currentUserRole
                  )
                "
                class="dropdown-item cursor-pointer"
                @click="getCandidates"
                :disabled="
                  getIsChildUserFromAccessToken && currentOptionIsParent
                    ? true
                    : false
                "
                >Candidate</CLink
              >
            </div>
          </CDropdown>
          <input
            name="email-search-bar"
            class="f-size m-0 form-control"
            v-model="searchTerm"
            @input="handleSearchTerm('email-search-bar', $event.target.value)"
          />
        </div>
        <div
          class="body"
          v-if="userEmailAddress.length || getCandidateEmailAddress.length"
        >
          <div class="body-content">
            <div class="user-list mt-1" v-if="userEmailAddress.length">
              <div
                v-for="(data, index) in userEmailAddress"
                :key="index"
                class="email-list p-2 cursor-pointer"
              >
                <span
                  class="user-email d-flex justify-content-start"
                  style="color: #768192"
                  @click="selectUserEmail(data.email)"
                >
                  <div class="user-icon">
                    <i
                      class="fas fa-user-circle"
                      :class="data.contact ? 'contact' : 'user'"
                    ></i>
                  </div>
                  <div class="user-detail d-block text-left pl-3 pr-3">
                    <label class="name mb-0">{{ data.full_name }}</label>
                    <p class="mail mb-0" style="font-size: 10px">
                      {{ data.email }}
                    </p>
                  </div>
                </span>
              </div>
            </div>
            <div
              class="candidate-list mt-1"
              v-if="getCandidateEmailAddress.length"
            >
              <div
                v-for="(data, index) in getCandidateEmailAddress"
                :key="index"
                class="email-list p-2 cursor-pointer"
              >
                <span
                  class="user-email d-flex justify-content-start"
                  style="color: #768192"
                  @click="selectUserEmail(data.email)"
                >
                  <div class="user-icon">
                    <i class="fas fa-user-circle candidate"></i>
                  </div>
                  <div class="user-detail d-block text-left pl-3 pr-3">
                    <label class="name mb-0">{{ data.full_name }}</label>
                    <p class="mail mb-0" style="font-size: 10px">
                      {{ data.email }}
                    </p>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="body" v-else>
          <div class="d-flex align-items-center h-100">
            <div class="container text-center">
              <h4>
                No Data Found<i
                  class="fas fa-ban ml-1"
                  :style="{ color: '#e55353' }"
                ></i>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import Select from "@/components/reusable/Fields/Select.vue";
import { mapGetters, mapActions } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import EmailConversation from "@/containers/Communication/EmailConversation";
import { Role, getScope } from "@/helpers/helper";
import { VueEditor } from "vue2-editor";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowSideBar: {
      type: Boolean,
      default: false,
    },
    isEmailSelection: {
      type: Boolean,
      default: false,
    },
    isCcSelection: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextInput,
    TextareaInput,
    EmailConversation,
    VueEditor,
    Select,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      mail: {
        org: null,
        org_type_id: null,
      },
      searchTerm: null,
      isShowDrop: false,
      currentOptionIsParent: false,
    };
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getUserEmailAddress",
      "getWindowWidth",
      "getCandidateEmailAddress",
      "getUserDetails",
      "isUKMainAgency",
      "organizationList",
      "getCustomerId",
      "isJobSupplierFromAccessToken",
      "isCandidateSupplierFromAccessToken",
      "organizationListForPartnerHsptl",
      "organizationListForPartnerAgency",
      "getOrgIDFromAccessToken",
      "organizationListForChild",
      "getIsChildUserFromAccessToken",
    ]),
    isCandidate() {
      return [this.Role.customerCandidate, this.Role.systemCandidate].includes(
        this.currentUserRole
      );
    },
    isCustomer() {
      return [this.Role.customerAdmin, this.Role.customerRecruiter].includes(
        this.currentUserRole
      );
    },
    windowWidth() {
      if (this.getWindowWidth > 991) {
        return true;
      }
      return false;
    },
    userEmailAddress() {
      return this.getUserEmailAddress
        .filter((row) => {
          const email = row.email.toLowerCase();
          const name = row.full_name.toLowerCase();
          const searchTerm = this.searchTerm?.toLowerCase() || "";

          return email.includes(searchTerm) || name.includes(searchTerm);
        })
        .sort((a, b) => Number(a.contact) - Number(b.contact));
    },
    options() {
      return {
        organisationList: this.isJobSupplierFromAccessToken
          ? this.organizationListForPartnerHsptl
          : this.isCandidateSupplierFromAccessToken
          ? this.organizationListForPartnerAgency
          : this.getIsChildUserFromAccessToken
          ? this.organizationListForChild
          : this.organizationList,
      };
    },
  },
  watch: {
    "options.organisationList"() {
      if (
        !this.mail?.org &&
        this.options["organisationList"] &&
        this.options["organisationList"]?.length &&
        this.getOrgIDFromAccessToken
      ) {
        let default_org = _(this.options["organisationList"])
          .filter((org) => org?.code == this.getOrgIDFromAccessToken)
          .value();
        this.handleChangeSelect("org", default_org[0]);
      }
    },
  },
  methods: {
    ...mapActions([
      "getConversation",
      "sendOutboundEmail",
      "showToast",
      "getEmailConversation",
      "fetchOrgLocation",
      "fetchAllUserEmailSearchTerm",
      "fetchAllCustomerContactUsersEmail",
      "fetchOrganizationId",
    ]),
    handleChangeSelect(name, value) {
      // disable candidate button for child
      this.currentOptionIsParent = value?.organisation?.is_parent
        ? true
        : false;

      // clear org detail
      this.mail.org_type_id = null;
      this.searchTerm = null;

      //set org value
      Vue.set(this.mail, name, value);
      if (value?.organisation?.organisation_type_id == 13)
        Vue.set(
          this.mail,
          "org_type_id",
          value?.organisation?.organisation_type_id
        );
      this.fetchAllCustomerContactUsersEmail({ org_id: value?.code });
      this.$store.commit("SET_CANDIDATE_EMAIL_ADDRESS", []);
    },
    handleSearchTerm(name, value) {
      // this.searchTerm = value;
      if (this.isCustomer)
        this.fetchAllUserEmailSearchTerm({
          searchTerm: value,
          org_id: this.mail?.org?.code,
        });
    },
    ShowHideSideBar() {
      if (this.isEmailSelection) {
        this.$emit("sidebarModalCallBack", "To");
      } else if (this.isCcSelection) {
        this.$emit("sidebarModalCallBack", "Cc");
      }
    },
    selectUserEmail(email) {
      if (this.isEmailSelection) {
        this.$emit("mailModalCallBack", "To", email);
      } else if (this.isCcSelection) {
        this.$emit("mailModalCallBack", "Cc", email);
      }
    },
    getCustomers(data) {
      this.fetchAllCustomerContactUsersEmail({
        contact: data,
        org_id: this.mail?.org?.code,
      });
      this.$store.commit("SET_CANDIDATE_EMAIL_ADDRESS", []);
      this.isShowDrop = false;
    },
    getCandidates() {
      this.fetchAllUserEmailSearchTerm({
        searchTerm: null,
        org_type_id: this.mail?.org_type_id,
        org_id: this.mail?.org?.code,
      });
      this.$store.commit("SET_USER_EMAIL_ADDRESS", []);
      this.isShowDrop = false;
    },
  },
  mounted() {
    if (
      !this.mail?.org &&
      this.options["organisationList"] &&
      this.options["organisationList"]?.length &&
      this.getOrgIDFromAccessToken
    ) {
      let default_org = _(this.options["organisationList"])
        .filter((org) => org?.code == this.getOrgIDFromAccessToken)
        .value();
      this.handleChangeSelect("org", default_org[0]);
    }
  },
};
</script>
<style lang="scss" scoped>
$primary: theme-color("primary");

.fa-users {
  position: absolute;
  right: 25px;
  top: 10px;
  color: #a7a6a6f2;
}

.dot-icon {
  position: absolute;
  top: 6px;
  right: 0px;
  color: #9e9e9e;
  font-size: 20px;
}

.user {
  color: #ff080873;
}
.contact {
  color: #93beff;
}
.candidate {
  color: #d4d4d4;
}

.fa-user-circle {
  font-size: 30px;
  position: relative;
}

.email-list:hover {
  background-color: #9a9a9a2b;
  border-radius: 5px;
}

.name {
  font-size: 13px;
  font-weight: 400;
  color: #606060;
}

.side-bar {
  .side-panel {
    width: 100%;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #f5f5f561;
    position: absolute;
    padding: 20px;
    z-index: 30;

    .header {
      align-items: center;
    }
    .body {
      overflow: inherit;
      overflow-x: hidden;
      // overflow-y: hidden;
      height: 90%;
    }
  }
  .side-backdrop {
    background-color: #0000000f;
    width: 60.2222222%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

.slide-fade-enter-active {
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.05s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(5px);
  opacity: 0;
}

@media screen and (min-width: 992px) {
  .side-panel {
    width: 320px !important;
  }
}
@media screen and (max-width: 992px) {
  .side-panel {
    background-color: #fff !important;
  }
}
</style>
