<template>
  <div class="email-modal">
    <CModal
      class="text-black"
      color="primary"
      size="lg"
      :show.sync="showEmailModal"
      :close-on-backdrop="false"
    >
      <template #header>
        <h6 v-if="subject && message" class="modal-title white">
          Interview Schedule Mail
        </h6>
        <h6 v-else-if="mail.mail_id" class="modal-title white">
          Email Conversation - {{ mail.mail_id }}
        </h6>
        <CButtonClose
          :disabled="isDisable"
          @click="modalCallBack"
          class="text-black"
        />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          :disabled="isDisable"
          @click="modalCallBack"
          >Close</CButton
        >
        <CButton
          type="button"
          v-if="emailBox"
          color="primary"
          class="px-4"
          @click="sendOutboundMail"
          :disabled="isSending"
          >{{ isSending ? "Sending" : "Send" }}</CButton
        >
      </template>
      <ValidationObserver ref="mailform" v-slot="{ handleSubmit }">
        <CRow class="row mb-3">
          <div
            @click="ShowHideNewMessage(), focus()"
            class="col-lg-12 col-md-12 col-sm-12 font-weight-bolder text-primary cursor-pointer"
          >
            <span v-if="!isShowCompose"
              ><i class="fa fa-paper-plane mr-2"></i>New email</span
            >
            <span v-if="isShowCompose"
              ><i class="fa fa-minus mr-2"></i>Hide</span
            >
          </div>
        </CRow>
        <transition name="fade">
          <form
            @submit.prevent="handleSubmit(SendOutboundMail)"
            name="mailform"
            v-if="isShowCompose"
            :style="
              isShowSideBar && windowWidth
                ? 'margin-right: 320px;'
                : 'margin-right: 0px;'
            "
          >
            <div class="p-2">
              <CRow class="row mb-3" v-if="emailBox">
                <label class="col-lg-12 col-md-12 required">Email</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <i
                    v-if="
                      [
                        Role.systemAdmin,
                        Role.customerAdmin,
                        Role.customerRecruiter,
                      ].includes(currentUserRole)
                    "
                    class="fa fa-users cursor-pointer"
                    aria-hidden="true"
                    @click="showHideSideBar('To')"
                  ></i>
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <TextInput
                      name="mail_id"
                      :value="mail.mail_id"
                      :error="errors[0]"
                      @change="handleInput"
                      :disabled="false"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow
                v-if="
                  [
                    Role.systemAdmin,
                    Role.customerAdmin,
                    Role.customerRecruiter,
                  ].includes(currentUserRole) && isShowCC
                "
                class="row mb-3"
              >
                <div class="d-flex col-lg-12 col-md-12 col-sm-12">
                  <label class="col-lg-6 col-md-6 p-0" v-visible="isShowCC"
                    >Cc
                    <em
                      class="fa-solid fa-circle-info ml-2 text-primary cursor-pointer"
                      v-c-tooltip="'Type comma to separate multiple email ids!'"
                    ></em
                  ></label>
                  <label class="col-lg-6 col-md-6 p-0">
                    <div
                      @click="isShowCC = !isShowCC"
                      class="text-primary cursor-pointer"
                      style="font-size: 11px"
                    >
                      <span v-if="isShowCC" class="float-right">Hide Cc</span>
                    </div>
                  </label>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <i
                    class="fa fa-at cursor-pointer"
                    aria-hidden="true"
                    @click="showHideSideBar('Cc')"
                  ></i>
                  <ValidationProvider rules="email" v-slot="{ errors }">
                    <Select
                      class="email-cc"
                      :name="'cc'"
                      :placeholder="''"
                      :value="mail.cc"
                      @input="handleInput"
                      :taggable="true"
                      :multiple="true"
                      :clearable="true"
                      :noDrop="true"
                      :mapKeydown="handlers"
                      :selectOnTab="true"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <div class="d-flex col-lg-12 col-md-12 col-sm-12">
                  <label class="col-lg-6 col-md-6 p-0 required">Subject</label>
                  <label
                    class="col-lg-6 col-md-6 p-0"
                    v-if="
                      [
                        Role.systemAdmin,
                        Role.customerAdmin,
                        Role.customerRecruiter,
                      ].includes(currentUserRole) && !isShowCC
                    "
                  >
                    <div
                      @click="isShowCC = !isShowCC"
                      class="text-primary cursor-pointer"
                      style="font-size: 11px"
                    >
                      <span v-if="!isShowCC" class="float-right">Show Cc</span>
                    </div>
                  </label>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <TextInput
                      name="subject"
                      :value="mail.subject"
                      @change="handleInput"
                      :error="errors[0]"
                      :disabled="disabled"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="col-lg-12 col-md-12 ng-4 required">Message</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="editor-container">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <vue-editor
                        class="message-body"
                        :class="{ 'is-danger': errors && errors[0] }"
                        name="message"
                        ref="editor"
                        :value="mail.message"
                        :text-change="textChange"
                        :editor-toolbar="customToolbar"
                        @input="handleEditor"
                      ></vue-editor>
                      <small class="has-error" v-if="errors[0]">{{
                        errors[0]
                      }}</small>
                    </ValidationProvider>
                  </div>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="col-lg-12 col-md-12">Attachment</label>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="d-flex justify-content-between">
                    <input
                      name="upload-document"
                      type="file"
                      ref="file"
                      @change="handleFile"
                      multiple
                    />
                    <div v-if="isCustomer">
                      <CButton
                        type="button"
                        color="primary"
                        class="float-right"
                        style="font-size: 11px"
                        @click="documentModal.isShowPopup = true"
                        >Choose from Candidate/Library Docs</CButton
                      >
                    </div>
                  </div>
                </div>
              </CRow>
              <CRow
                class="ml-2 mr-2 d-flex align-items-center documentScrolling document-table"
                v-if="files.length !== 0"
              >
                <code
                  v-for="(data, index) in files"
                  :key="index"
                  class="text-primary"
                >
                  <span>{{ data.name }}</span>
                  <span class="nx-90 cursor-pointer">
                    <i
                      class="fas fa-trash ml-3"
                      @click="deleteDocuments(index, data)"
                    ></i>
                  </span>
                </code>
              </CRow>
              <CRow class="row mt-4">
                <div class="col-lg12 col-md-12 col-sm-12">
                  <p class="text-primary m-0" style="font-size: 10px">
                    <span class="text-black">Note :</span> File attachments per
                    email should be less than 2.5 Mb
                  </p>
                </div>
              </CRow>
              <CRow class="row mb-3" v-if="!emailBox">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <CButton
                    type="button"
                    v-if="btnHideSend"
                    color="primary"
                    class="float-right"
                    @click="sendOutboundMail"
                    :disabled="isSending"
                    >{{ isSending ? "Sending" : "Send Email" }}</CButton
                  >
                  <CButton
                    type="button"
                    v-if="btnHideNext"
                    color="primary"
                    class="float-right"
                    @click="openCommentModal, (isDisable = false)"
                    >Next</CButton
                  >
                </div>
              </CRow>
            </div>
          </form>
        </transition>
        <transition name="slide-fade">
          <EmailSideBar
            v-if="isShowSideBar"
            :isShowSideBar="isShowSideBar"
            :isEmailSelection="isEmailSelection"
            :isCcSelection="isCcSelection"
            @sidebarModalCallBack="showHideSideBar"
            @mailModalCallBack="selectedEmail"
          />
        </transition>
      </ValidationObserver>
      <EmailConversation
        v-if="!emailBox"
        :email_subject="email_subject"
        :recruiter_message="recruiter_message"
        @conversationModalCallBack="conversationModalCallBack"
      />
    </CModal>
    <transition name="fade">
      <EmailDocumentModal
        v-if="documentModal.isShowPopup"
        :isShowPopup="documentModal.isShowPopup"
        :defaultSearch="
          !emailBox && { name: 'searchTerm', value: mail.mail_id }
        "
        @documentModalCallBack="documentModalCallBack"
        @documentFileAddCallBack="handleFile"
        @documentFileRemoveCallBack="removeFile"
      />
    </transition>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { Role, getScope } from "@/helpers/helper";
import { VueEditor } from "vue2-editor";

extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import EmailSideBar from "@/containers/Communication/EmailSideBar";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import EmailConversation from "@/containers/Communication/EmailConversation";
import EmailDocumentModal from "@/containers/Communication/EmailDocumentModal";

export default {
  directives: {
    visible: {
      // directive definition
      componentUpdated: function (el, binding) {
        el.style.visibility = binding.value ? "visible" : "hidden";
      },
    },
  },
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    email_id: {
      type: String,
      default: null,
    },
    subject: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    sender: {
      type: String,
      default: null,
    },
    sender_name: {
      type: String,
      default: null,
    },
    customer_user_id: {
      type: Number,
      default: null,
    },
    email_subject: {
      type: String,
      default: null,
    },
    job_id: {
      type: Number,
      default: null,
    },
    candidate_uid: {
      type: Number,
      default: null,
    },
    alternate_email: {
      type: String,
      default: null,
    },
    recruiter_message: {
      type: Boolean,
      default: false,
    },
    emailBox: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextInput,
    TextareaInput,
    EmailConversation,
    EmailSideBar,
    VueEditor,
    Select,
    EmailDocumentModal,
  },
  data() {
    return {
      Role,
      currentUserRole: getScope(),
      mail: {
        sender: "",
        mail_id: this.email_id,
        cc: [],
        bcc: "",
        subject: this.subject,
        message: this.message,
        org: null,
        org_type_id: null,
      },
      files: [],
      btnHideSend: true,
      btnHideNext: false,
      messageId: null,
      isDisable: false,
      isSending: false,
      isShowCompose: false,
      customToolbar: [
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // ['blockquote', 'code-block'],
        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        // [{ 'font': [] }],                                 // font
        // ['clean']                                         // remove formatting button
      ],
      textChange: "source",
      isShowSideBar: false,
      isEmailSelection: false,
      isCcSelection: false,
      isShowCC: false,
      documentModal: {
        isShowPopup: false,
      },
      fileSize: 0,
    };
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getUserEmailAddress",
      "getWindowWidth",
      "getCandidateEmailAddress",
      "getUserDetails",
      "isUKMainAgency",
      "organizationList",
      "getCustomerId",
      "isJobSupplierFromAccessToken",
      "isCandidateSupplierFromAccessToken",
      "organizationListForPartnerHsptl",
      "organizationListForPartnerAgency",
      "getOrgIDFromAccessToken",
      "organizationListForChild",
      "getIsChildUserFromAccessToken",
      "storedOrgDocIds",
      "storedCandidateDocIds",
      "emailCc",
    ]),
    isCandidate() {
      return [this.Role.customerCandidate, this.Role.systemCandidate].includes(
        this.currentUserRole
      );
    },
    isCustomer() {
      return [this.Role.customerAdmin, this.Role.customerRecruiter].includes(
        this.currentUserRole
      );
    },
    getUserName() {
      let firstname = this.getUser.first_name;
      let lastname = this.getUser.surname;

      if (firstname && lastname) {
        firstname = firstname
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .trim();

        lastname = lastname
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .trim();

        return firstname + " " + lastname;
      }
      return false;
    },
    getUserEmail() {
      return this.getUser.full_name;
    },
    getUserId() {
      return this.getUser.id;
    },
    disabled() {
      if (this.isCandidate) {
        let subject = this.email_subject.split(": ")[1]
          ? this.email_subject
          : "Re: " + this.email_subject;
        this.mail.subject = subject;
        return true;
      } else {
        if (this.recruiter_message) {
          let subject = this.email_subject.split(": ")[1]
            ? this.email_subject.split(": ")[1]
            : this.email_subject;
          this.mail.subject = subject;
          return true;
        } else return false;
      }
    },
    windowWidth() {
      if (this.getWindowWidth > 991) {
        return true;
      }
      return false;
    },
    userEmailAddress() {
      return this.getUserEmailAddress.filter((row) => {
        const email = row.email.toLowerCase();
        const name = row.full_name.toLowerCase();
        const searchTerm = this.searchTerm?.toLowerCase() || "";

        return email.includes(searchTerm) || name.includes(searchTerm);
      });
    },
    options() {
      return {
        organisationList: this.isJobSupplierFromAccessToken
          ? this.organizationListForPartnerHsptl
          : this.isCandidateSupplierFromAccessToken
          ? this.organizationListForPartnerAgency
          : this.getIsChildUserFromAccessToken
          ? this.organizationListForChild
          : this.organizationList,
      };
    },
    isPertemps() {
      return (
        this.isUKMainAgency ||
        this.isJobSupplierFromAccessToken ||
        this.isCandidateSupplierFromAccessToken
      );
    },
    showEmailModal() {
      return this.isShowPopup && !this.documentModal.isShowPopup;
    },
  },
  methods: {
    ...mapActions([
      "getConversation",
      "sendOutboundEmail",
      "showToast",
      "getEmailConversation",
      "fetchOrgLocation",
      "fetchAllUserEmailSearchTerm",
      "fetchAllCustomerContactUsersEmail",
      "fetchOrganizationId",
      "fetchEmailCc",
      "resetSelectedDocumentIds",
    ]),
    handleInput(name, value) {
      Vue.set(this.mail, name, value);
      if (name == "mail_id" && email.validate(value, 0)) {
        if (this.isUKMainAgency) {
          let main_contact = true;
          this.fetchEmailCc({
            email: value,
            main_contact,
            customer_uid: this.getCustomerId,
          }).then((res) => {
            if (
              res?.data &&
              this.emailCc.alternate_email &&
              !this.mail.cc.includes(this.emailCc.alternate_email)
            ) {
              this.mail.cc.push(this.emailCc.alternate_email);
              this.isShowCC = true;
            }
          });
        } else {
          let main_contact = false;
          this.fetchEmailCc({
            email: value,
            main_contact,
            customer_uid: this.getCustomerId,
          }).then((res) => {
            if (
              res?.data &&
              this.emailCc.alternate_email &&
              !this.mail.cc.includes(this.emailCc.alternate_email)
            ) {
              this.mail.cc.push(this.emailCc.alternate_email);
              this.isShowCC = true;
            }
          });
        }
      }
    },
    handleEditor(data) {
      Vue.set(this.mail, "message", data);
    },
    handleFile(event) {
      this.fileSize +=
        event instanceof File ? event.size : event.target.files[0].size;
      if (this.fileSize / (1024 * 1024) <= 2.5) {
        if (event instanceof File) {
          this.files.push(event);
        } else {
          for (var i = 0; i < event.target.files.length; i++) {
            this.files.push(event.target.files[i]);
          }
        }
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "File size too big!",
        });
        this.fileSize -=
          event instanceof File ? event.size : event.target.files[0].size;
      }
      this.$refs.file.value = null;
    },
    removeFile(file) {
      let index = this.files.findIndex((e) => e.name === file.name);
      this.deleteDocuments(index, file);
    },
    conversationModalCallBack(delete_id, subject) {
      this.$emit("conversationModalCallBack", delete_id, subject);
    },
    openCommentModal() {
      this.$emit("openCommentModal", this.messageId, "email");
    },
    modalCallBack() {
      this.$emit("emailModalCallBack", "email");
    },
    async sendOutboundMail() {
      const isValid = await this.$refs.mailform.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.isSending = true;
      let sender;
      let sender_name;
      let customer_user_id;
      if (this.isCandidate) {
        sender = this.sender;
        sender_name = this.sender_name;
        customer_user_id = this.customer_user_id;
      } else {
        sender = this.getUserEmail;
        sender_name = this.getUserName;
        customer_user_id = this.getUserId;
      }
      let appendAction = this.sendOutboundEmail({
        sender: sender,
        sender_name: sender_name,
        customer_user_id: customer_user_id,
        email_to: this.mail.mail_id,
        subject: this.mail.subject,
        message: this.mail.message,
        job_id: this.job_id,
        candidate_uid:
          this.candidate_uid || this.emailCc?.candidate_uid || null,
        files: this.files,
        email_cc: this.mail.cc,
      });
      Promise.all([appendAction]).then((res) => {
        if (res) {
          if ([500, 422, 400, 401, 403, 404, 322, 502].includes(res)) {
            this.isSending = false;
          } else if (
            (this.currentUserRole === this.Role.systemAdmin &&
              this.$router.currentRoute.name == "Candidate") ||
            (this.currentUserRole === this.Role.customerAdmin &&
              this.$router.currentRoute.name == "Candidate") ||
            (this.currentUserRole === this.Role.systemRecruiter &&
              this.$router.currentRoute.name == "Candidate") ||
            (this.currentUserRole === this.Role.customerRecruiter &&
              this.$router.currentRoute.name == "Candidate")
          ) {
            this.isSending = false;
          } else if (
            (this.currentUserRole === this.Role.systemAdmin &&
              this.$router.currentRoute.name === "Job List Preview") ||
            (this.currentUserRole === this.Role.customerAdmin &&
              this.$router.currentRoute.name === "Job List Preview") ||
            (this.currentUserRole === this.Role.systemRecruiter &&
              this.$router.currentRoute.name === "Job List Preview") ||
            (this.currentUserRole === this.Role.customerRecruiter &&
              this.$router.currentRoute.name === "Job List Preview")
          ) {
            this.btnHideSend = false;
            this.btnHideNext = true;
            this.isDisable = true;
            this.messageId = res[0]?.data.split("-")[1];
          } else if (this.isCandidate) {
            this.isSending = false;
          } else if (this.recruiter_message) {
            this.isSending = false;
          } else {
            this.isSending = false;
          }
          //Clear Control
          this.$refs.mailform.reset();
          if (this.emailBox) this.mail.mail_id = null;
          this.mail.subject = this.disabled ? this.mail.subject : null;
          this.mail.message = null;
          this.$refs.file.value = null;
          this.mail.cc = [];
          this.files = []; //Clear for every selection
          this.resetSelectedDocumentIds();
          let sender;
          if (this.isCandidate) {
            sender = this.sender;
          } else {
            sender = this.getUserEmail;
          }
          this.getEmailConversation({
            email: encodeURIComponent(this.mail.mail_id),
            sender: sender,
          });
        }
      });
    },
    focus() {
      this.$nextTick(
        function () {
          if (this.$refs.editor) {
            this.$refs.editor.$el.lastElementChild.firstElementChild.classList.add(
              "form-control"
            );
          }
        }.bind(this)
      );
    },
    ShowHideNewMessage() {
      this.isShowCompose = !this.isShowCompose;
    },
    deleteDocuments(index, data) {
      if (this.storedOrgDocIds.includes(data.name)) {
        this.$store.commit(
          "STORED_ORG_DOC_IDS",
          _.without(this.storeOrgDocIds, data.name)
        );
      } else if (this.storedCandidateDocIds.includes(data.name)) {
        this.$store.commit(
          "STORED_CANDIDATE_DOC_IDS",
          _.without(this.storedCandidateDocIds, data.name)
        );
      }
      if (index != -1) this.files.splice(index, 1);
      this.fileSize -= data.size;
    },
    showHideSideBar(action) {
      this.isShowSideBar = !this.isShowSideBar;
      if (action == "To") this.isEmailSelection = !this.isEmailSelection;
      if (action == "Cc") this.isCcSelection = !this.isCcSelection;
    },
    selectedEmail(action, mail) {
      if (action == "To" && this.mail.mail_id != mail) {
        this.handleInput("mail_id", mail);
        this.isEmailSelection = false;
        this.isShowSideBar = false;
      } else if (action == "Cc" && !this.mail.cc.includes(mail)) {
        this.mail.cc.push(mail);
        if (!this.windowWidth) {
          this.isCcSelection = false;
          this.isShowSideBar = false;
        }
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "Email already exist!",
        });
      }
    },
    documentModalCallBack() {
      this.documentModal.isShowPopup = false;
    },
    handlers: (map, vm) => ({
      ...map,
      188: (e) => {
        e.preventDefault();
        vm.$nextTick(() => vm.$refs.search.focus());
        return vm.typeAheadSelect();
      },
    }),
  },
  mounted() {
    if (this.emailBox == false) {
      let sender = this.isCandidate ? this.sender : this.getUserEmail;
      this.getEmailConversation({
        email: encodeURIComponent(this.mail.mail_id),
        sender: sender,
      });
    }
    if (!this.email_id || (this.subject && this.message)) {
      this.ShowHideNewMessage();
    }
    if (this.isCustomer) {
      this.fetchOrganizationId({
        customer_uid: this.getCustomerId,
      });
      if (!this.isPertemps) {
        this.fetchOrgLocation();
      }
      this.handleInput("mail_id", this.email_id);
    }
    this.focus();
    this.resetSelectedDocumentIds();
  },
};
</script>
<style lang="scss" scoped>
$primary: theme-color("primary");
.white {
  color: white;
  margin-right: 6px;
  margin-top: 2px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
code {
  padding: 0px 6px 0px 6px;
  margin: 4px;
  border-radius: 3px;
  background: #eee;
}
.nx-90 {
  margin-bottom: 6px;
}
.ng-4 {
  position: absolute;
}

.fa-users,
.fa-at {
  position: absolute;
  right: 25px;
  top: 10px;
  color: #a7a6a6f2;
  z-index: 30;
}

.slide-fade-enter-active {
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.05s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(5px);
  opacity: 0;
}

@media screen and (min-width: 992px) {
  .side-panel {
    width: 320px !important;
  }
}
@media screen and (max-width: 992px) {
  .side-panel {
    background-color: #fff !important;
  }
}
</style>

<style lang="scss">
.message-body {
  .ql-toolbar,
  .ql-container {
    border: none !important;
  }
  .ql-toolbar {
    position: relative;
    top: -5px;
  }
  .ql-container {
    font-family: inherit;
  }
  .ql-toolbar .ql-formats {
    float: right;
    margin: 0 !important;
  }

  .ql-snow .ql-stroke {
    stroke: #a7a6a6f2 !important;
  }
  .ql-toolbar .ql-formats .ql-active,
  .ql-toolbar .ql-formats button:focus,
  .ql-toolbar .ql-formats button:hover,
  .ql-toolbar .ql-formats .ql-active .ql-stroke {
    stroke: #dd3651 !important;
  }

  .ql-toolbar .ql-formats button svg {
    max-width: 18px;
    max-height: 18px;
  }
}

.email-cc {
  .vs__actions {
    display: none !important;
  }

  .vs__selected {
    background-color: #fafafa !important;
    border-color: #ebe2e2 !important;
  }
}
</style>
