<template>
  <div class="phone-modal">
    <CModal
      class="text-black"
      :show.sync="isShowModal"
      :close-on-backdrop="false"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">Phone - {{ message.phone }}</h6>
        <CButtonClose @click="modalCallBack" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="modalCallBack"
          >No</CButton
        >
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="makeCall(message.phone)"
          >Yes</CButton
        >
      </template>
      <p>Are you sure to proceed with this call?</p>
    </CModal>
    <CToaster :position="position" class="phone p-cursor" v-if="getCallItems">
      <template>
        <CToast :show.sync="getCallItems" class="bg-transparent b-overwrite"
          ><ul>
            <li>
              <CButton
                v-if="getMute"
                type="button"
                color="primary"
                class="items ml-1 mr-1"
                @click="mute(false)"
              >
                <span class="material-icons md-20">
                  mic_off
                </span></CButton
              >
              <CButton
                v-else
                type="button"
                color="primary"
                class="items ml-1 mr-1"
                @click="mute(true)"
              >
                <span class="material-icons md-20">
                  keyboard_voice
                </span></CButton
              >
            </li>
            <li>
              <CButton
                type="button"
                color="danger"
                class="items ml-1 mr-1"
                @click="hangUp"
              >
                <span class="material-icons md-20">
                  call_end
                </span></CButton
              >
            </li>
          </ul>
        </CToast>
      </template>
    </CToaster>
  </div>
</template>
<script>
import Vue from "vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import { mapGetters, mapActions } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Role, getScope } from "@/helpers/helper";
import Communication from "@/modules/communication";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    phone: {
      type: String,
      default: null,
    },
  },
  components: {
    TextInput,
    TextareaInput,
  },

  data() {
    return {
      Role,
      currentUserRole: getScope(),
      message: {
        phone: this.phone.replace("^", ""),
        message: null,
      },
      max: 1600,
      isShowCallItems: false,
      position: "bottom-center",
      isShowAck: true,
    };
  },
  computed: {
    ...mapGetters(["getUser", "getMute", "getCallItems"]),
    getUserName() {
      let firstname = this.getUser.first_name;
      let lastname = this.getUser.surname;

      if (firstname && lastname) {
        firstname = firstname
          .replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .replace(/\s/g, "");

        lastname = lastname
          .replace(/\w\S*/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
          .replace(/\s/g, "");

        return firstname + " " + lastname;
      }
    },
    isShowModal() {
      return this.isShowPopup && this.isShowAck ? true : false;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "makeVoiceCall",
      "muteCall",
      "Hangup",
      "getVoiceCallDevice",
    ]),
    modalCallBack() {
      this.$emit("phoneModalCallBack", "phone");
    },
    makeCall(phone) {
      this.makeVoiceCall(phone);
      this.isShowAck = false;
    },
    mute(bool) {
      this.muteCall(bool);
    },
    hangUp() {
      this.Hangup();
    },
  },
  mounted() {
    this.getVoiceCallDevice();
  },
};
</script>

<style lang="scss" scoped>
.phone {
  bottom: 60px;
  width: 156px;

  .b-overwrite {
    border-radius: 50px;

    ul {
      padding: 0px 14px;
      margin: 0 auto;
    }

    .items {
      padding: 6px 6px;
      width: 35px;
      height: 35px;
      border-radius: 50px;
    }

    ul li {
      display: inline-block;
    }
  }
}

.md-20 {
  font-size: 20px;
}
</style>
