<template>
  <div class="email-modal">
    <CModal
      class="text-black email-docs"
      :show.sync="showPopup"
      :close-on-backdrop="false"
      color="primary"
      size="lg"
    >
      <template #header
        ><h6 class="modal-title text-white">Document</h6>
        <CButtonClose @click="documentModalCallBack" class="text-black"
      /></template>
      <template #footer>
        <CButton
          type="button"
          color="primary"
          variant="outline"
          class="px-4"
          @click="documentModalCallBack"
          >Close</CButton
        >
      </template>
      <div class="d-flex justify-content-between w-100">
        <div class="col-lg-12 col-md-12 col-sm-12 px-0">
          <div>
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  href="#email-config"
                  target="_self"
                  class="nav-link bg-white rounded-0"
                  :class="{ active: currentTab == 'Candidate Docs' }"
                  @click="currentTab = 'Candidate Docs'"
                  >Candidate Docs</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="#email-assets"
                  target="_self"
                  class="nav-link bg-white rounded-0"
                  :class="{ active: currentTab == 'Library Docs' }"
                  @click="currentTab = 'Library Docs'"
                  >Library Docs</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div
          class="tab-pane"
          :class="{ active: currentTab == 'Candidate Docs' }"
          :style="currentTab == 'Candidate Docs' ? '' : 'display:none;'"
        >
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div v-if="isShowHideCandidate">
              <div class="generic-filter mt-3">
                <SearchBar
                  :value="searchTerm"
                  :searchCallback="handleSearchTerm"
                  :placeholder="'Search by candidate id, name and email id'"
                  class="my-1"
                />
              </div>
              <CRow
                class="row mt-4"
                v-if="
                  getCandidateEmailAddress && getCandidateEmailAddress.length
                "
              >
                <div
                  class="col-sm-6 col-md-3"
                  v-for="(item, index) in getCandidateEmailAddress"
                  :key="index"
                >
                  <CCard
                    class="candidate-card cursour-pointer"
                    :style="{ height: windowWidth ? '180px' : '230px' }"
                    @click="
                      getCandidateDocument(item), (isShowHideCandidate = false)
                    "
                  >
                    <CCardBody>
                      <div class="content-title mb-3 text-center">
                        <div class="image-wrapper">
                          <img
                            :src="item.profile_picture"
                            class="rounded-circle"
                            :class="getWindowWidth > 1200 ? 'w-50' : 'w-25'"
                            alt="DP"
                            @error="$event.target.src = userIcon"
                          />
                        </div>
                        <div class="mt-4">
                          <span class="d-block">
                            <h6 class="m-0 mt-2 text-wrapper">
                              {{ item.full_name }}
                            </h6>
                          </span>
                          <span class="d-block">
                            <p
                              class="m-0 mt-2 text-wrapper"
                              style="font-size: 11px"
                            >
                              {{ item.email }}
                            </p>
                          </span>
                        </div>
                      </div>
                    </CCardBody></CCard
                  >
                </div>
              </CRow>
              <CRow class="row" v-else>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <ul class="list-group list-group-flush" colspan="4">
                    <li class="my-5 list-style-none">
                      <div class="text-center my-5">
                        <h5 class="text-center" :style="'color:'">
                          No Data Found
                          <i
                            class="fas fa-ban"
                            :style="{ color: '#e55353' }"
                          ></i>
                        </h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </CRow>
            </div>
            <div v-else>
              <CRow>
                <div class="col-lg-12 col-md-12 col-sm-12 p-0">
                  <div class="float-back-button position-absolute mt-2 mx-3">
                    <CButton
                      type="button"
                      color="primary"
                      class="px-4"
                      @click="isShowHideCandidate = true"
                      >Back</CButton
                    >
                  </div>
                  <CDataTable
                    :items="getDocuments"
                    :fields="documentFields"
                    :items-per-page="10"
                    :tableFilter="{
                      placeholder: 'Type for search',
                    }"
                    hover
                    pagination
                  >
                    <template #select_to_attach="{ item }">
                      <td>
                        <input
                          type="checkbox"
                          :checked="checkFileExist(item, 'candidate')"
                          @click="selectToAttach($event, item, 'candidate')"
                        />
                      </td>
                    </template>
                    <template #preview="{ item }">
                      <td>
                        <a
                          class="cursor-pointer"
                          v-if="item && item.link"
                          @click="preview(item)"
                        >
                          <i class="fas fa-eye ml-3"></i>
                        </a>
                      </td>
                    </template>
                    <template #no-items-view>
                      <ul class="list-group list-group-flush" colspan="4">
                        <li class="my-5">
                          <div class="text-center my-5">
                            <h5 class="text-center" :style="'color:'">
                              No Data Found
                              <i
                                class="fas fa-ban"
                                :style="{ color: '#e55353' }"
                              ></i>
                            </h5>
                          </div>
                        </li>
                      </ul>
                    </template>
                  </CDataTable>
                </div>
              </CRow>
            </div>
          </div>
        </div>
        <div
          class="tab-pane"
          :class="{ active: currentTab == 'Library Docs' }"
          :style="currentTab == 'Library Docs' ? '' : 'display:none;'"
        >
          <div class="col-lg-12 col-md-12 col-sm-12 p-0">
            <CDataTable
              :items="library"
              :fields="documentFields"
              :items-per-page="10"
              :tableFilter="{
                placeholder: 'Type for search',
              }"
              hover
              pagination
            >
              <template #select_to_attach="{ item }">
                <td>
                  <input
                    v-if="checkFileExist(item, 'org')"
                    type="checkbox"
                    v-c-tooltip="{
                      content:
                        'Use delete icon from email screen to remove the files!',
                      show: false,
                      placement: 'bottom',
                      appendToBody: true,
                    }"
                    :checked="checkFileExist(item, 'org')"
                    @click="selectToAttach($event, item, 'org')"
                  />
                  <input
                    v-else
                    type="checkbox"
                    :checked="checkFileExist(item, 'org')"
                    @click="selectToAttach($event, item, 'org')"
                  />
                </td>
              </template>
              <template #preview="{ item }">
                <td>
                  <a
                    class="cursor-pointer"
                    v-if="item && item.link"
                    @click="preview(item)"
                  >
                    <i class="fas fa-eye ml-3"></i>
                  </a>
                </td>
              </template>
              <template #no-items-view>
                <ul class="list-group list-group-flush" colspan="4">
                  <li class="my-5">
                    <div class="text-center my-5">
                      <h5 class="text-center" :style="'color:'">
                        No Data Found
                        <i class="fas fa-ban" :style="{ color: '#e55353' }"></i>
                      </h5>
                    </div>
                  </li>
                </ul>
              </template>
            </CDataTable>
          </div>
        </div>
      </div>
    </CModal>
    <PreviewModal
      v-if="previewModal.isShowPopup"
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
  </div>
</template>
<script>
import pdf from "vue-pdf";
import moment from "moment";

import { mapGetters, mapActions } from "vuex";
import { BASE_URL } from "@/service_urls";

import PreviewModal from "@/components/reusable/PreviewModal";
import SearchBar from "@/components/reusable/GenericFilterAndSearch/searchComponent";

import userIcon from "../../../public/img/user_default_pic.png";
import { appendAccessToken } from "../../helpers/helper";

export default {
  name: "EmailDocumentModal",
  components: {
    PreviewModal,
    SearchBar,
  },
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    defaultSearch: {
      default: null,
    },
  },
  data() {
    return {
      userIcon,
      currentTab: "Candidate Docs",
      selectedOrg: null,
      searchTerm: null,
      documentFields: [
        {
          key: "select_to_attach",
          label: "Select to attach",
          _style: "width: 25%",
        },
        { key: "document_name", label: "Name", _style: "width: 25%" },
        {
          key: "document_type",
          label: "Type",
          _style: "width: 25%",
        },
        { key: "preview", label: "Preview", _style: "width: 25%" },
      ],
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      isShowHideCandidate: true,
    };
  },
  computed: {
    ...mapGetters([
      "getUser",
      "getWindowWidth",
      "getCandidateEmailAddress",
      "getOrgIDFromAccessToken",
      "isJobSupplierFromAccessToken",
      "isCandidateSupplierFromAccessToken",
      "organizationList",
      "organizationListForPartnerHsptl",
      "organizationListForPartnerAgency",
      "organizationListForChild",
      "getIsChildUserFromAccessToken",
      "getUploadedDocument",
      "customerDocumentLibrary",
      "storedOrgDocIds",
      "storedCandidateDocIds",
    ]),
    options() {
      return {
        organisationList: this.isJobSupplierFromAccessToken
          ? this.organizationListForPartnerHsptl
          : this.isCandidateSupplierFromAccessToken
          ? this.organizationListForPartnerAgency
          : this.getIsChildUserFromAccessToken
          ? this.organizationListForChild
          : this.organizationList,
      };
    },
    showPopup() {
      return !this.previewModal.isShowPopup && this.isShowPopup;
    },
    documents() {
      return this.currentTab == "Candidate Docs"
        ? this.getDocuments
        : this.library;
    },
    library() {
      return (
        (this.customerDocumentLibrary.length &&
          this.customerDocumentLibrary.map((v) => ({
            link: v?.organisation_document?.link_to_doc,
            updatedDate:
              moment(v?.organisation_document?.created_on).format(
                "DD-MM-YYYY"
              ) || "--",
            document_type: v?.organisation_document?.comments,
            document_name: v?.organisation_document?.document_name || "--",
            document_ext:
              v?.organisation_document?.document_ext?.document_ext || "--",
            document_id:
              v?.organisation_document?.organisation_document_id || "--",
            comments: v?.organisation_document?.comments,
            document_type_id: v?.organisation_document?.document_type_id,
            version_no: v?.organisation_document?.version_no,
          }))) ||
        []
      );
    },
    getDocuments() {
      return (
        (this.getUploadedDocument.length &&
          this.getUploadedDocument.map(
            ({
              document_type_id,
              uuid_link_to_doc,
              document_name,
              document_type,
              created_on,
              document_ext,
              candidate_document_id,
              comments,
              version_no,
            }) => {
              return {
                link: uuid_link_to_doc,
                document_type: document_type.comments,
                document_name: document_name,
                updatedDate: moment(created_on).format("DD-MM-YYYY"),
                document_ext: document_ext?.document_ext,
                document_id: candidate_document_id,
                comments: comments,
                document_type_id: document_type_id,
                version_no: version_no,
              };
            }
          )) ||
        []
      );
    },
    windowWidth() {
      if (this.getWindowWidth > 991) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "fetchAllUserEmailSearchTerm",
      "fetchOrganizationId",
      "fetchDocumentByCandidateID",
      "fetchCustomerDocumentLib",
      "fetchDocumentFile",
      "storeSelectedDocumentIds",
      "removeSelectedDocumentIds",
    ]),
    handleSearchTerm(item) {
      this.fetchAllUserEmailSearchTerm({
        searchTerm: item.value,
        org_id: this.selectedOrg?.org?.code,
      });
    },
    getCandidateDocument(item) {
      this.fetchDocumentByCandidateID({
        customer_uid: item?.customer_uid,
        candidate_uid: item?.candidate_uid,
      });
    },
    selectToAttach(event, item, type) {
      if (
        this.storedCandidateDocIds.includes(item.document_name) === false &&
        this.storedOrgDocIds.includes(item.document_name) === false
      ) {
        if (event.target.checked) {
          this.fetchDocumentFile({
            url: appendAccessToken(item.link.replace("/api/v1/", "")),
            document_name: item.document_name,
          }).then((res) => {
            if (res instanceof File) {
              this.$emit("documentFileAddCallBack", res);
              this.storeSelectedDocumentIds({ name: item.document_name, type });
              this.showToast({
                class: "bg-success text-white",
                message: "File Attached Successfully",
              });
            }
          });
        } else {
          this.fetchDocumentFile({
            url: item.link.replace("/api/v1/", ""),
            document_name: item.document_name,
          }).then((res) => {
            if (res instanceof File) {
              this.$emit("documentFileRemoveCallBack", res);
              this.removeSelectedDocumentIds({
                name: item.document_name,
                type,
              });
              this.showToast({
                class: "bg-danger text-white",
                message: "File Removed Successfully",
              });
            }
          });
        }
      } else {
        event.preventDefault();
        this.showToast({
          class: "bg-danger text-white",
          message: "File already Exist",
        });
      }
    },
    preview(data) {
      const _baseURL = BASE_URL;
      const _base_domain = _baseURL.substring(0, _baseURL.indexOf("/api"));
      const ext = data?.document_ext;
      const link_to_document = appendAccessToken(`${_base_domain}${data.link}`);
      this.iframe.url = link_to_document;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(link_to_document);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(ext)) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    checkFileExist(item, type) {
      if (type == "org") {
        return this.storedOrgDocIds.some((e) => e == item.document_name);
      } else {
        return this.storedCandidateDocIds.some((e) => e == item.document_name);
      }
    },
    modalCallBack() {
      this.previewModal.isShowPopup = false;
    },
    documentModalCallBack() {
      this.$emit("documentModalCallBack");
    },
  },
  mounted() {
    if (this.defaultSearch) {
      this.searchTerm = this.defaultSearch?.value;
      this.handleSearchTerm(this.defaultSearch);
    } else if (
      this.options["organisationList"] &&
      this.options["organisationList"]?.length &&
      this.getOrgIDFromAccessToken
    ) {
      this.selectedOrg = _(this.options["organisationList"])
        .filter((org) => org?.code == this.getOrgIDFromAccessToken)
        .value()[0];
      this.fetchAllUserEmailSearchTerm({
        searchTerm: null,
        org_type_id: this.selectedOrg?.org_type_id,
        org_id: this.selectedOrg?.org?.code,
      });
      this.fetchCustomerDocumentLib({ request_type: "document" });
    }
  },
};
</script>
<style lang="scss">
.email-docs {
  .modal-body {
    padding: 0 !important;

    .nav-tabs .nav-link.active {
      border-bottom: 2px solid #ffffff !important;
    }

    .nav-tabs .nav-link:hover,
    .nav-tabs .nav-link:focus {
      border-color: none !important;
    }

    .generic-filter {
      display: flex;
      padding: 4px 0;
      border: 1px solid #c9cbce;
      color: #00626a;
      background-color: #f1f2f5;
      align-items: center;
      justify-content: space-between;

      .search {
        max-width: 100% !important;
        flex: none;
      }

      p {
        font-weight: 600;
        font-size: 18px;
      }
    }

    .float-back-button {
      z-index: 99;
    }

    .form-inline {
      max-width: 100% !important;
      flex: none !important;
      justify-content: end;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .text-wrapper {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .link {
      color: blue;
      font-size: 10px;
      font-weight: 400;
    }

    input[type="checkbox"] {
      margin-top: -30px;
    }

    input[type="checkbox"] {
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeSpeed;
      width: 14px;
      height: 14px;
      margin: 0 1px 0 0;
      display: block;
      float: left;
      position: relative;
      cursor: pointer;

      &:after {
        content: "";
        vertical-align: middle;
        text-align: center;
        line-height: 13px;
        position: absolute;
        cursor: pointer;
        height: 19px;
        width: 19px;
        left: 0px;
        top: 0px;
        font-size: 10px;
        border: solid 2px #ccc;
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.1);
        background: #fff;
        border-radius: 3px;
      }

      &:hover:after {
        box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
      }

      &:checked:after {
        border-color: #767676;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAAiUlEQVR4AbXStQECMQBA0V9iS+HW4LAmNW6zpMbd4dz9LvriIaKniOBp8QqKCvwya6rAp8PrAC906DvDKw2g7gDfrAWUORnhhpWKtYHilxlgkTjDb+pGFyhwNF/MnNSbjt6sB+S/zAx+aZIEBSDLwX57Zm8KmTdz3McpVfauN9w39HEpnK5ZJM8Ldd7hI/X/HugAAAAASUVORK5CYII=");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
      }
    }

    label {
      input[type="checkbox"] {
        margin-top: 4px;
        margin-right: 10px;
      }
    }

    td,
    th {
      text-align: center;
      vertical-align: middle;
    }

    td > input {
      display: inline !important;
      float: none !important;
    }
  }
}
</style>
